<template>
  <div class="trackingNumber">
    <el-card>
      <div slot="header" class="clearfix">
        <div style="margin-bottom: 20px;">
          物流订阅
        </div>
        <el-form :inline="true" ref="form" :model="form" class="demo-form-inline" size="mini">
          <el-form-item label="请选择订阅日期：" >
            <el-select v-model="form.date" clearable>
              <el-option v-for="(item,i) in subscribeDateList" :key="i" :label="item"
                         :value="item"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item class="btn-right">
            <el-button type="danger" @click="subscribeAll" >一键订阅</el-button>
          </el-form-item>
        </el-form>
      </div>

    </el-card>

  </div>
</template>

<script>
import {subscribeByDate} from '@/services/logistics'

import {find30BeforeToday} from "@/services/special";

export default {
  name: 'subscribe',
  data() {
    return {
      subscribeDateList:[],
      form: {
        date: ''
      }
    }
  },
  methods: {

    // 一键订阅物流信息
    async subscribeAll() {
      const {data} = await subscribeByDate(this.form)
      this.$message.warning(data.message)
    },

    async load30BeforeToday() {
      const {data} = await find30BeforeToday()
      this.subscribeDateList = data.data
    },

    getNow() {
      const today = new Date();
      const year = today.getFullYear();
      const month = String(today.getMonth() + 1).padStart(2, '0');
      const day = String(today.getDate()).padStart(2, '0');
      const formattedDate = `${year}-${month}-${day}`;
      return formattedDate;
    }

  },
  created() {
    this.load30BeforeToday()
    this.form.date = this.getNow();
  },
  filters: {

  }
}
</script>

<style lang="scss" scoped>

</style>
